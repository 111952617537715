<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2>Novo Projeto</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do projeto</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 100px; min-height: 32px">
            <erp-s-field view="ll" :label="!project.active ? 'Projeto desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="project.active" id="active"/>
            </erp-s-field>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 120px; min-height: 32px">
            <erp-s-field view="ll" label="Público"
                         icon-help="Marcar como público libera o acesso ao projeto para qualquer pessoa">
              <sl-checkbox no-yes-no-val v-model="project.public" id="active"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Nome">
                  <erp-input placeholder="Defina um nome para o projeto" v-model="project.name" required autofocus/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 300px">
                <erp-s-field label="Código">
                  <erp-input placeholder="Defina um código único para o projeto" v-model="project.code"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Tipo">
                  <type-select v-model="project.type"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Líder">
                  <person-select v-model="project.owner"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Cliente">
                  <person-select v-model="project.client"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Permissão">
                  <erp-select v-model="project.permission" :options="persmissionsOpts"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Descrição sobre o projeto" v-model="project.description" class="sl-textarea"
                            rows="3"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <window-fila-tab />
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Projeto" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
<!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
          <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                     self="top right" :offset="[0, 0]">
            <div>
              <ul>
                <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
              </ul>
            </div>
          </u-popover>
        </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'
// import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'

import {newProject, updateProject, findProject} from "@/domain/project/services"
// import DatetimeInput from "@/reuse/input/Datetime"
import mockProject from "@/domain/project/helpers/mockProject"
import {convertRealToMoney, REAL_BRL} from "@/utils/money"
import {VMoney} from "v-money"
// import {datePtToEn} from "@/utils/date"
import SlCheckbox from "@/reuse/input/Checkbox"
// import {addMonths, format, parseISO} from "date-fns"
import TypeSelect from "components/suporte/components/projetos/include/TypeSelect"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import {PERMISSIONS} from "@/domain/project/helpers/types"
import WindowFilaTab from "components/suporte/components/projetos/include/WindowFilaTab"

export default {
  name: 'ProjetoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {money: VMoney},
  components: {
    WindowFilaTab,
    ErpSelect,
    PersonSelect,
    TypeSelect,
    SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      money: REAL_BRL,
      percent: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      project: JSON.parse(JSON.stringify(mockProject))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    persmissionsOpts() {
      return Object.keys(PERMISSIONS).map(k => {
        return {
          label: PERMISSIONS[k].title,
          value: Number(k)
        }
      })
    }
  },
  methods: {
    load() {
      this.loading = true
      findProject(this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.project.id = response.data.id
            }
            this.preventBind = true
            this.project = Object.assign({}, this.project, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.project)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.project))
      /*const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = convertRealToMoney(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })*/

      const relacoes = ['type', 'owner', 'client']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })
      return data
    },
    prepareToEdit(data) {
      /*const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })*/
      /*const datas = ['dataCompetencia', 'dataVencimento', 'dataPagamento']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })*/
      if (data.pago) {
        this.isPago = true
      }
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newProject
        if (this.project.id) {
          method = updateProject
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.project.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Projeto com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Novo Projeto com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              if ((!this.$refs.uploader || (this.$refs.uploader && !this.$refs.uploader.existsFilesToUpload)) && (type === 'andClose' || (this.entidade && this.entidadeId))) {
                // this.$uloc.window.close(this.$root.wid)
              } else if (type === 'andNew') {
                this.preventBind = true
                this.project = JSON.parse(JSON.stringify(mockProject))
                this.$nextTick(() => {
                  this.preventBind = false
                })
              }
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    }
  }
}
</script>
