export default {
    id: null,
    image: null,
    code: null,
    name: null,
    description: null,
    permission: 0,
    public: false,
    type: null,
    owner: null,
    members: null,
    client: null,
    moneyCost: null,
    moneyReceipts: null,
    queues: null,
    active: true
}
