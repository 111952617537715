<template>
  <div class="body-content">
    <e-row class="prelegend">
      <e-col class="flex content-end">
        <div>
          <legend class="legend-simple">Filas</legend>
          <p class="font-11 text-blue-grey m-b-none">Filas criadas aqui são exclusivas do projeto. Para gerenciar filas globais, utilize o módulo de configurações globais do ERP para filas de atendimento</p>
        </div>
      </e-col>
    </e-row>
    <e-row v-for="(fila, index) in filas" :key="'fila-' + index" mr class="m-t">
      <template v-if="fila.project">
      <e-col>
        <erp-s-field label="Nome da fila">
          <erp-input :autofocus="!project.id" v-model="fila.name" />
        </erp-s-field>
      </e-col>
      <e-col>
        <erp-s-field label="Prioridade">
          <erp-select v-model="fila.priority" :options="priorityOpts" />
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 200px">
        <erp-s-field label="Ativo">
          <div class="m-l-xs"><sl-checkbox class="m-t-xs" v-model="fila.active" /></div>
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 200px">
        <erp-s-field label="Remover">
          <a @click="remover(fila)" class="text-negative m-t-sm block">Excluir fila</a>
        </erp-s-field>
      </e-col>
      <e-col style="max-width: 100px" v-if="typeof fila._status !== 'undefined' && fila._status !== null">
        <erp-s-field label="Status">
          <div class="m-t-sm">
            <sl-loading class="size-18" content="" v-if="fila._status === 'loading'" />
            <span v-if="fila._status === 'ok'" class="text-green">Atualizado</span>
          </div>
        </erp-s-field>
      </e-col>
      </template>
      <template v-else>
        <e-col>
          {{fila.name}}
        </e-col>
        <e-col>Prioridade: {{filaPriority[fila.priority].title}}</e-col>
        <e-col>
          <span class="u-chip basic u-chip-small bg-green font-12 text-white m-l-xs">Fila Global</span>
        </e-col>
      </template>
    </e-row>
    <e-row class="m-b" v-if="!filas || !filas.length" mr>
      <e-col>Nenhuma fila criada.</e-col>
    </e-row>
    <e-row class="m-t" mr>
      <e-col>
        <u-btn label="Criar fila" color="green" no-caps @click="criarFila" class="sl-btn" />
        <u-btn label="Salvar alterações da fila" color="primary" class="sl-btn m-l-sm" no-caps @click="salvar" />
        <u-btn v-if="editing" label="Cancelar alterações" color="primary" class="sl-btn m-l-sm bg-white text-blue-grey" flat no-caps @click="cancelar" />
      </e-col>
    </e-row>
  </div>
</template>

<script>
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import SlCheckbox from "@/reuse/input/Checkbox"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import WindowTabMixin from "components/suporte/components/projetos/include/WindowTabMixin"
import mockFila from "@/domain/project/helpers/mockFila"
import {FILA_PRIORITY} from "@/domain/project/helpers/types"
import {updateQueue, newQueue, deleteQueue} from "@/domain/tarefas/services/queue"
import SlLoading from "components/layout/components/Loading"

export default {
  name: "WindowFilaTab",
  mixins: [WindowTabMixin],
  data () {
    return {
      editing: false,
      filas: []
    }
  },
  computed: {
    filaPriority () {
      return FILA_PRIORITY
    },
    priorityOpts() {
      return Object.keys(FILA_PRIORITY).map(k => {
        return {
          label: FILA_PRIORITY[k].title,
          value: Number(k)
        }
      })
    }
  },
  mounted() {
    if (this.project._queues && this.project._queues.length) {
      this.project._queues.map(fila => {
        this.filas.push({
          ...fila,
          _status: null
        })
      })
    }
  },
  methods: {
    criarFila () {
      this.editing = true
      const fila = JSON.parse(JSON.stringify(mockFila))
      fila.project = this.project.id
      this.filas.push(fila)
    },
    salvar () {
      this.filas.map(async (fila, key) => {
        if (!fila.project) {
          return
        }
        let method = newQueue
        if (fila.id) {
          method = (data) => updateQueue(fila.id, data, 'PATCH')
        }
        fila._status = 'loading'
        const data = {
          id: fila.id,
          name: fila.name,
          priority: fila.priority,
          active: fila.active
        }
        await method(data)
            .then(response => {
              const f = response.data
              // fila._status = 'ok'
              f._status = 'ok'
              Object.assign(this.filas[key], f)
            })
            .catch(error => {
              fila._status = 'error'
              this.alertApiError(error)
            })
      })
    },
    cancelar () {
      if (this.filas.length) {
        this.filas.map(fila => {
          if (!fila.id) {
            this.filas.splice(this.filas.indexOf(fila), 1)
          }
        })
      }
      this.editing = false
    },
    remover (fila) {
      this.$uloc.dialog({
        title: 'Remover fila',
        message: `Tem certeza que deseja remover a fila ${fila.name} ?`,
        noCaps: true,
        ok: `Sim, remover`,
        cancel: 'Não',
        color: 'negative'
      })
          .then(() => {
            deleteQueue(fila.id)
                .then(response => {
                  this.filas.splice(this.filas.indexOf(fila), 1)
                  this.dg('Fila removida')
                })
                .catch(error => {
                  this.alertApiError(error)
                })
          })
          .catch(() => {})
    }
  },
  components: {
    SlLoading,
    ErpInput,
    ErpSelect,
    ERow,
    ECol,
    SlCheckbox,
    ErpSField
  }
}
</script>
